const local = {};

local.getItem = (item) => {
  try {
    return JSON.parse(sessionStorage.getItem(item));
  } catch (error) {
    return null;
  }
};

local.setItem = (item, value) => {
  sessionStorage.setItem(item, JSON.stringify(value));
};

local.removeItem = (item) => {
  sessionStorage.removeItem(item);
};

local.clear = () => {
  sessionStorage.clear();
};

export default local;
