import moment from "moment";

var Parser = require("js-expression").Parser;

let expression = new Parser();

expression.addOperator(".", 10, function (a, b) {
  return a[b];
});

expression.addOperator("datetime", null, function (inputDateString) {
  try {
    return moment(inputDateString).format("DD/MM/YYYY");
  } catch (error) {
    return null;
  }
});

expression.addFunction(
  "ternary",
  function (condition, exprIfTrue, exprIfFalse) {
    return condition ? exprIfTrue : exprIfFalse;
  }
);

expression.addFunction(
  "getValueFromArray",
  function (arr, data, key = "code", label = "name") {
    let value = arr.find((item) => item[key] === data);
    if (!value) return null;
    return value[label];
  }
);

expression.addFunction("currency", function (value) {
  return Number(value).toLocaleString('vi-VN') + ' ₫'
})

export default expression;
