import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { local } from "services";

const PrivateRoute = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    let session = local.getItem("session");
    // if (!session) {
    //   return history.push("/login");
    // }
  }, []);

  return <div>{children}</div>;
};

export default PrivateRoute;
