import React, { useState } from "react";
import Icon from "../../../../assets/Vector (3).svg";
import UploadScreenItem from "./UploadScreenItem";
import FolderIcon from "../../../../assets/folder.png";

const UploadScreenFolder = (props) => {
  const [isShow, setIsShow] = useState(true);

  return (
    <div>
      <div
        className={`flex justify-between mb-3 mt-3 ml-3 border-bottom-1`}
        onClick={() => setIsShow(!isShow)}
      >
        <div className="flex items-center">
          <div style={{ width: "20px" }}>
            <img src={FolderIcon} width={18} />
          </div>
          <p style={{ textAlign: "left", marginLeft: "7px" }}>
            Thư mục {props?.stt + 1}
          </p>
        </div>
        <p className="mt-2">
          <img
            src={Icon}
            alt=""
            style={{
              transform: isShow ? "rotate(180deg)" : "rotate(0deg)",
              transition: "0.3s",
            }}
          />
        </p>
      </div>
      {props.data && (
        <div style={{ display: isShow ? "flex" : "none", flexWrap: "wrap" }}>
          {props.data?.map((item, index) => (
            <UploadScreenItem docid={item} key={index} isFirst={index === 0} />
          ))}
        </div>
      )}
      <div
        style={{
          height: "1px",
          width: "100%",
          background: "#C0E1FF",
        }}
      />
    </div>
  );
};

export default UploadScreenFolder;
