const constant = {};

constant.SERVICEID = {
  APPLICATION_DETAIL: "64880fe3ab984c72ba592d8a",
};

constant.STEPID_PATH = {
  SE_Input_information: "/eSign_signing_login",
  SE_Review: "/eSign_view_contract",
  SE_Input_OTP: "/eSign_verify_es",
  SE_eKYC: "/eSign_start_confirm_infor",
  SE_Finish: "/eSign_view_contract",
  SE_Go_to_counter: "/eSign_view_contract",
};

constant.IDENTITY_TYPE = {
  CCCD: "Căn cước công dân",
  CMND: "Chứng minh nhân dân",
  CMQD: "Chứng minh Quân đội",
  PASSPORT: "Hộ chiếu"
};

constant.IDENTITY = {
  CCCD: "Số CCCD",
  CMND: "Số CMND",
  CMQD: "Số CM Quân đội",
  PASSPORT: "Hộ chiếu"
}

constant.STEP_NO = {
  SE_Input_information: 1,
  SE_Review: 2,
  SE_Input_OTP: 4,
  SE_eKYC: 3,
  SE_Finish: 5,
  SE_Go_to_counter: 3.5,
};

export default constant;
