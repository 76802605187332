import React from "react";
import { useHistory } from "react-router-dom";
import { DETAILS } from "screens/Application/application-constant";
import IconBack from "../../../../assets/Back.svg";
import { expression } from "services";

const Profile = (props) => {
  const history = useHistory();
  let { item, data } = history?.location?.state || {};
  let fields = DETAILS[item?.key];
  const renderFields = (fields, data) => {
    return fields.map((field, index) => {
      if (!data?.[field.field]) return <></>;
      return (
        <div key={index}>
          <h2 className="font-medium">{fields.label}</h2>
          <div className="flex justify-between mb-2 mt-2 border-bottom-1">
            <p style={{ color: "#999999" }}>{field?.label}</p>
            <p
              className={`${
                field?.className ? field.className : "w-1/2 text-right"
              }`}
            >
              {field?.function
                ? field?.render
                  ? field.render(data?.[field.compare], data?.[field.field])
                  : data?.[field.field]
                : field?.render
                ? field.render(data, data?.[field.field])
                : data?.[field.field]}
            </p>
          </div>
          <hr />
        </div>
      );
    });
  };

  return (
    <div className="fixed z-10 left-0 right-0 md:max-w-lg mx-auto mt-3">
      <div className="w-24 ml-3.5">
        {" "}
        <button
          onClick={() => {
            history.goBack();
          }}
        >
          <img src={IconBack} alt="IconBack" />
        </button>
      </div>
      <h2 className="font-medium px-5 ">{fields.label}</h2>
      <div
        className="scrollbar mt-4 px-5"
        style={{ height: "calc(100vh - 104px)" }}
        id="style-1"
      >
        <div>
          {fields.fields?.length > 0 && renderFields(fields?.fields, data)}
          {fields.cells?.map((cell, index) => {
            if (
              cell.condition &&
              !expression.parse(cell.condition).evaluate(data)
            ) {
              return null;
            }
            return (
              <div key={index}>
                <h3 className="font-medium mb-2 mt-2">{cell.label}</h3>
                {renderFields(cell.fields, data)}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Profile;
