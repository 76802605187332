import Profile from "screens/Application/screens/Profile";
import UploadScreen from "screens/Application/screens/UploadScreen";

const routes = [
  {
    path: "/applicationReview_information",
    component: <Profile />,
  },
  {
    path: "/applicationReview_residence",
    component: <Profile />,
  },
  {
    path: "/applicationReview_job",
    component: <Profile />,
  },
  {
    path: "/applicationReview_reference",
    component: <Profile />,
  },
  {
    path: "/applicationReview_disbursement",
    component: <Profile />,
  },
  {
    path: "/applicationReview_upload",
    component: <UploadScreen />,
  },
];

export default routes;
