import React, { useEffect } from "react";
import "./Loading.css";

const LoadingModal = () => {
  useEffect(() => {
    const modalLoading = document.getElementById("modal-loading");
    if (modalLoading) modalLoading.classList.add("show");
    return () => {
      if (modalLoading) modalLoading.classList.remove("show");
    };
  }, []);
  return (
    <div
      className="fixed top-0 bottom-0 md:max-w-lg w-full z-20 flex items-center justify-center px-8"
      style={{ background: "rgba(0,0,0,0.3)" }}
    >
      <div className="modal" id="modal-loading" data-backdrop="static">
        <div className="modal-dialog modal-sm">
          <div className="modal-content">
            <div className="modal-body text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                width={150}
                height={150}
                style={{
                  shapeRendering: "auto",
                  display: "block",
                  background: "transparent",
                }}
              >
                <g>
                  <path
                    stroke="none"
                    fill="white"
                    d="M23 50A27 27 0 0 0 77 50A27 29.9 0 0 1 23 50"
                  >
                    <animateTransform
                      values="0 50 51.45;360 50 51.45"
                      keyTimes="0;1"
                      repeatCount="indefinite"
                      dur="0.5319148936170213s"
                      type="rotate"
                      attributeName="transform"
                    />
                  </path>
                  <g />
                </g>
              </svg>
              <span style={{ color: "white" }}>Loading, please wait...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingModal;
