import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import Header from "components/Header";
import routes from "routes";
import PrivateRoute from "components/PrivateRoute/APL";

import "./App.css";
import "./output.css";
import "react-toastify/dist/ReactToastify.css";

import Loading from "components/Loading/Modal";

const Notification = React.lazy(() => import("screens/Notification"));
const Application = React.lazy(() => import("screens/Application"));
const VerifyOTP = React.lazy(() => import("./screens/VerifyOTP"));
const Layout = React.lazy(() => import("./layouts/Layout"));
const Login = React.lazy(() => import("./screens/Login"));
const RequestEdit = React.lazy(() =>
  import("./screens/Application/screens/RequestEdit")
);

const E_Signing = React.lazy(() => import("screens/E_Sign/E-Signing"));
const LoginESign = React.lazy(() => import("screens/E_Sign/Login"));
const VerifyOtpES = React.lazy(() => import("screens/E_Sign/VerifyOTPESign"));
const ReviewContract = React.lazy(() => import("screens/E_Sign/View-Contract"));
const ConfirmInfor = React.lazy(() =>
  import("screens/E_Sign/Verify-Infor/ConfirmInfor")
);
const ContractSuccess = React.lazy(() =>
  import("screens/E_Sign/ContractSuccess")
);
const GoToCounter = React.lazy(() =>
  import("screens/E_Sign/components/GoToCounter")
);
const ErrorLink = React.lazy(() => import("screens/ErrorLink"));

const App = () => {
  return (
    <Router>
      <ToastContainer />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/applicationReview" exact>
            <PrivateRoute>
              <Layout>
                <Application />
              </Layout>
            </PrivateRoute>
          </Route>
          <Route path="/error">
            <ErrorLink />
          </Route>
          <Route path="/applicationReview_login">
            <Layout>
              <Login />
            </Layout>
          </Route>
          <Route path="/applicationReview_verify">
            <Layout>
              <VerifyOTP />
            </Layout>
          </Route>
          {/* E_Signing */}
          <Route path="/eSign" exact>
            <Layout>
              <E_Signing />
            </Layout>
          </Route>
          <Route path="/eSign_signing_login">
            <Layout>
              <LoginESign />
            </Layout>
          </Route>
          <Route path="/eSign_verify_es">
            <Layout>
              <VerifyOtpES />
            </Layout>
          </Route>
          <Route path="/eSign_view_contract">
            <Layout>
              <ReviewContract />
            </Layout>
          </Route>
          <Route path="/eSign_start_confirm_infor">
            <Layout>
              <ConfirmInfor />
            </Layout>
          </Route>
          <Route path="/eSign_download_contract">
            <Layout>
              <ContractSuccess />
            </Layout>
          </Route>
          <Route path="/eSign_go_to_counter">
            <Layout>
              <GoToCounter />
            </Layout>
          </Route>
          {routes.map((route, index) => {
            let ChildComponent = route.component;
            return (
              <Route path={route.path} key={index}>
                <Layout>
                  <Header />
                  {ChildComponent}
                </Layout>
              </Route>
            );
          })}
          <Route path="/applicationReview_notification">
            <PrivateRoute>
              <Layout>
                <Notification />
              </Layout>
            </PrivateRoute>
          </Route>
          <Route path="/applicationReview_requestEdit">
            <PrivateRoute>
              <Layout>
                <RequestEdit />
              </Layout>
            </PrivateRoute>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
