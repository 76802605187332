import config from "./config";
import local from "./local";
import DeviceDetector from "device-detector-js";
const deviceDetector = new DeviceDetector();
const request = {};

request.request = async (
  url,
  data,
  headers,
  method = "POST",
  isPublic = false
) => {
  try {
    url = `${config.host}${url}`;
    let option = {
      method, // or 'PUT'
      body: JSON.stringify({
        ...data,
        DEVICEID: JSON.stringify(deviceDetector.parse(navigator.userAgent)),
      }), // data can be `string` or {object}!
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        Authorization: `Bearer ${local.getItem("session") || "customer"}`,
      },
    };
    option.headers = Object.assign({}, option.headers, headers);
    if (method === "GET") delete option.body;

    if (config.debug) console.log(`[${method}]`, url, option);

    let res = await fetch(url, option);

    let rs;
    if (option.headers.Accept === "text/plain") {
      rs = [await res.blob(), res];
    } else {
      rs = await res.json();
    }
    if (config.debug) console.log(`[RESPONSE]`, url, rs);
    if (rs.errorCode == "8013") {
      local.clear()
      window.location.href = "/error"
    }
    return rs;
    // switch (res.status) {
    //   case 401:
    //     if (rs?.errorMsg === "Invalid token") {
    //       const event = new CustomEvent("tokenExpired", {
    //         detail: rs.errorMsg,
    //       });
    //       window.dispatchEvent(event);
    //     } else {
    //       helper.toast(
    //         "error",
    //         rs.errorMsg || i18next.t("internalServerError")
    //       );
    //       window.location.href = "/#/login";
    //     }
    //     break;
    //   case 403:
    //     Swal.fire({
    //       title: i18next.t("forbidden"),
    //       text: "You don't have permission",
    //       icon: "error",
    //       confirmButtonText: "OK",
    //     });
    //     break;
    //   case 500:
    //     helper.toast("error", rs.errorMsg || i18next.t("internalServerError"));
    //     break;
    //   case 200:
    //     if (rs && rs.errorCode === 0) {
    //       return rs;
    //     } else {
    //       helper.toast(
    //         "error",
    //         rs.errorMsg || i18next.t("internalServerError")
    //       );
    //       return rs;
    //     }
    //   case 404:
    //     if (isPublic) {
    //       window.location.href = "/#/404";
    //     } else {
    //       helper.toast("error", rs.errorMsg || i18next.t("dataNotFound"));
    //     }
    //     break;
    //   case 400:
    //     if (rs.code && rs.code === "E_MISSING_OR_INVALID_PARAMS") {
    //       helper.toast("error", "Invalid parameters");
    //     } else {
    //       helper.toast("error", rs.errorMsg || i18next.t("badRequest"));
    //     }
    //     break;
    //   default:
    //     throw rs;
    // }
  } catch (err) {
    console.log("res", err);
    throw err;
  }
};

export default request;
